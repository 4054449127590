import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PuffLoader from "react-spinners/PuffLoader";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const Loading = ({ currentUser }) => {
  const history = useHistory();

  useEffect(() => {
    if (currentUser !== null && currentUser !== undefined) {
      history.push("/home");
    }
  }, [currentUser]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const token = localStorage.getItem("EdictDigitalToken");

      if (token !== "" && token !== null && token !== undefined) {
        // Wait
      } else {
        window.location.href = "https://yedek.okumaplatformu.com/signin";
      }
    }, 200);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <PageLayout>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          position: "fixed",
        }}
      >
        <PuffLoader color="#67BCEC" size="100" />
      </div>
    </PageLayout>
  );
};

Loading.defaultProps = {
  currentUser: null,
};

Loading.propTypes = {
  currentUser: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
});

export default connect(mapStateToProps, null)(Loading);
