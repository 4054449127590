import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import SuiBox from "components/SuiBox";
import { getMaterialListOfBook } from "redux/actions/pages/assignment";
import requireAuth from "hocs/requireAuth";
import { Grid } from "@mui/material";
import SuiBadgeDotLevelColor from "components/SuiBadgeDotLevelColor";
import SuiButton from "components/SuiButton";
import { BookOpen, Backpack } from "phosphor-react";
import SummaryLessonPlanContainer from "./components/SummaryLessonPlanContainer";

// eslint-disable-next-line no-unused-vars
const BookInfo = ({ getMaterials, localizations }) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    getMaterials({ bookId: location.state?.bookId });
  }, [location.state]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <div style={{ width: "100%", textAlign: "center" }}>
              <img
                src={location.state?.coverImage}
                alt=""
                style={{ width: "100%", objectFit: "contain" }}
              />
              {location.state?.bookLevel ? (
                <SuiBadgeDotLevelColor
                  size="lg"
                  // badgeContent={localizations[location.state.bookLevel] ?? ""}
                  badgeContent={`${localizations[location.state.bookLevel] ?? ""}${
                    location.state?.bookInUse === "CLASSROOM" ? " (Sınıf içi)" : ""
                  }`}
                  color={location.state.bookLevel}
                  style={{ display: "flex", justifyContent: "center" }}
                />
              ) : null}
              <Grid container mt={0.5} spacing={1.5}>
                <Grid item xs={12} lg={12}>
                  <SuiButton
                    href={`https://yedek.okumaplatformu.com/read/${location.state?.bookId}`}
                    target="_blank"
                    variant="contained"
                    color="info"
                    style={{
                      // backgroundColor: "#67BCEC",
                      // color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                    fullWidth
                  >
                    <BookOpen size={24} weight="duotone" color="white" />
                    <span
                      style={{
                        lineHeight: "24px",
                        marginLeft: "10px",
                        fontSize: "20px",
                        textTransform: "none",
                      }}
                    >
                      Oku
                    </span>
                  </SuiButton>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <SuiButton
                    variant="contained"
                    color="info"
                    style={{
                      // backgroundColor: "#BE5FB5",
                      // color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                    fullWidth
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: "/assignment/new",
                        state: { bookId: location.state?.bookId },
                      });
                    }}
                  >
                    <Backpack size={24} weight="duotone" color="white" />
                    <span
                      style={{
                        lineHeight: "24px",
                        marginLeft: "10px",
                        fontSize: "20px",
                        textTransform: "none",
                      }}
                    >
                      Görev Ata
                    </span>
                  </SuiButton>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} lg={9}>
            <SummaryLessonPlanContainer
              course={location.state?.course}
              bookLevel={location.state?.bookLevel}
              bookOrder={location.state?.bookOrder}
              bookTitle={location.state?.bookTitle}
              coverImage={location.state?.coverImage}
              bookGrades={location.state?.bookGrades}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
};

BookInfo.defaultProps = {
  localizations: null,
};

BookInfo.propTypes = {
  getMaterials: PropTypes.func.isRequired,
  localizations: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  getMaterials: getMaterialListOfBook,
};

export default connect(mapStateToProps, mapDispatchToProps)(requireAuth(BookInfo));
