/* eslint-disable no-console */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";

// Soft UI Dashboard PRO React example components
import SidenavCollapse from "custom/Sidenav/SidenavCollapse";
import SidenavList from "custom/Sidenav/SidenavList";
import SidenavItem from "custom/Sidenav/SidenavItem";
// import SidenavCard from "custom/Sidenav/SidenavCard";

// Custom styles for the Sidenav
import SidenavRoot from "custom/Sidenav/SidenavRoot";
// import sidenavLogoLabel from "custom/Sidenav/styles/sidenav";
import sidenavDropdown from "custom/Sidenav/styles/sidenavDropdown";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setMiniSidenav } from "context";
import brand from "assets/images/logo-small.png";

import { connect } from "react-redux";
import { selectSidenavItem } from "redux/actions/pages";
import { Calendar } from "phosphor-react";
import { iconMapper } from "mappers/icon.mapper";

function Sidenav({
  selectedSuborganization,
  allSuborganizations,
  localizations,
  selectItem,
  color,
  routes,
  ...rest
}) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const itemName = pathname.split("/").slice(1)[1];

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (parentId, collapses) =>
    collapses.map(({ id, name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            name={name}
            active={key === itemName}
            open={openNestedCollapse === name}
            onClick={() =>
              openNestedCollapse === name
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(name)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem
              name={name}
              active={route === pathname}
              onClick={() => {
                selectItem({ responsibility: parentId, screen: id });
              }}
            />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ id, type, name, icon, title, collapse, noCollapse, key, href }) => {
      let returnValue;

      if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              color={color}
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <SidenavCollapse
            color={color}
            key={key}
            name={name}
            icon={icon}
            active={key === collapseName}
            open={openCollapse === name}
            onClick={() => (openCollapse === name ? setOpenCollapse(false) : setOpenCollapse(name))}
          >
            {collapse ? renderCollapse(id, collapse) : null}
          </SidenavCollapse>
        );
      } else if (type === "title" && title && title !== "") {
        returnValue = (
          <SuiTypography
            key={key}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            opacity={0.6}
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </SuiTypography>
        );
      } else if (type === "divider") {
        returnValue = <Divider key={key} />;
      }

      return returnValue;
    }
  );

  const getAllSuborganizationsOptionValues = () => {
    if (allSuborganizations && allSuborganizations.length > 0) {
      return allSuborganizations.map((sub) => ({
        value: sub.id,
        label:
          localizations &&
          Object.keys(localizations).length > 0 &&
          localizations[sub.suborganizationKey]
            ? localizations[sub.suborganizationKey]
            : sub.suborganizationKey,
      }));
    }
    return [];
  };

  // eslint-disable-next-line no-unused-vars
  const getDefaultSuborganizationOptionValue = () => {
    if (selectedSuborganization && selectedSuborganization > 0) {
      return getAllSuborganizationsOptionValues().find(
        (op) => op.value === selectedSuborganization
      );
    }

    return null;
  };

  const setSelectedSuborganization = ({ value }) => {
    selectItem({ suborganization: value });
  };

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
      <SuiBox pt={3} pb={1} px={4} textAlign="center">
        <SuiBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <SuiTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </SuiTypography>
        </SuiBox>
        <SuiBox component={NavLink} to="/" display="flex" alignItems="center">
          <SuiBox component="img" src={brand} alt="Okuma Platformu Logo" width="100%" />
        </SuiBox>
      </SuiBox>
      <Divider />
      {allSuborganizations.length > 1 ? (
        <>
          {localizations?.SUBORGANIZATION ? (
            <SuiTypography
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              opacity={0.6}
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {localizations?.SUBORGANIZATION}
            </SuiTypography>
          ) : null}
          <SuiBox sx={(theme) => sidenavDropdown(theme)}>
            <SuiSelect
              size="small"
              placeholder={localizations?.SUBORGANIZATION_SELECT_PLACEHOLDER}
              options={getAllSuborganizationsOptionValues()}
              onChange={setSelectedSuborganization}
              value={getDefaultSuborganizationOptionValue()}
            />
          </SuiBox>
          <Divider />
        </>
      ) : null}
      <>
        {renderRoutes.length > 1 ? (
          <>
            <List>{renderRoutes}</List>
            <Divider />
          </>
        ) : null}
      </>
      <SuiBox pt={0} my={0} mx={0}>
        <NavLink to="/all-books" key="all-books" sx={{ textDecoration: "none" }}>
          <SidenavCollapse
            color={color}
            name={localizations?.NAV_ALL_BOOKS ?? "Tüm Kitaplar"}
            icon={
              pathname === "/all-books"
                ? iconMapper[localizations?.ALL_BOOKS_ICON ?? "Books"]({
                    weight: "duotone",
                    color: "white",
                  })
                : iconMapper[localizations?.ALL_BOOKS_ICON ?? "Books"]({
                    weight: "duotone",
                  })
            }
            active={pathname === "/all-books"}
            noCollapse
          />
        </NavLink>
      </SuiBox>
      <SuiBox pt={0} my={0} mx={0}>
        <NavLink to="/calendar" key="calendar" sx={{ textDecoration: "none" }}>
          <SidenavCollapse
            color={color}
            name="Takvim"
            icon={
              pathname === "/calendar" ? (
                <Calendar weight="duotone" color="white" />
              ) : (
                <Calendar weight="duotone" />
              )
            }
            active={pathname === "/calendar"}
            noCollapse
          />
        </NavLink>
      </SuiBox>
      {/* <Divider />
      <SuiBox pt={2} my={2} mx={2}>
        <SidenavCard />
      </SuiBox> */}
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectItem: PropTypes.func.isRequired,
  allSuborganizations: PropTypes.arrayOf(PropTypes.object),
  selectedSuborganization: PropTypes.number,
  localizations: PropTypes.objectOf(PropTypes.any),
};

Sidenav.defaultProps = {
  allSuborganizations: [],
  selectedSuborganization: 0,
  localizations: null,
};

const mapStateToProps = (state) => ({
  allSuborganizations: state.auth?.currentUser?.suborganizations,
  selectedSuborganization: state.header?.suborganization,
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = { selectItem: selectSidenavItem };

export default connect(mapStateToProps, mapDispatchToProps)(Sidenav);
