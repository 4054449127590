import React from "react";
import PropTypes from "prop-types";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { connect } from "react-redux";

ChartJS.register(Tooltip, Legend, ArcElement, ChartDataLabels);

const TaskCompletionSummary = ({ taskCompletion, localizations }) => {
  if (taskCompletion) {
    return (
      <div className="student-task-summary">
        <div className="section-header">
          {localizations?.TASK_COMPLETION_HEADER ?? "Görev Tamamlama"}
        </div>

        <div className="chart-container" style={{ paddingRight: "30%" }}>
          <Pie
            options={{
              // cutout: '50%',
              responsive: true,
              plugins: {
                tooltip: {
                  callbacks: {
                    label: (tooltipItem) => ` ${tooltipItem.label}: %${tooltipItem.formattedValue}`,
                  },
                },
                legend: {
                  display: true,
                  onClick: () => {},
                  position: "top",
                  align: "start",
                  labels: {
                    usePointStyle: true,
                    pointStyle: "circle",
                    padding: 20,
                    font: { size: 16 },
                  },
                },
                title: {
                  display: false,
                  text: "Görev Tamamlama Yüzdesi",
                  align: "start",
                },
                datalabels: {
                  display: true,
                  color: "#FFF",
                  font: { size: 16, weight: "bold" },
                  formatter: (value) => (value > 0.0 ? `% ${value}` : ""),
                },
              },
            }}
            data={{
              labels: [
                localizations?.TASK_COMPLETION_COMPLETED_LABEL ?? "Tamamlanan Çalışmalar",
                localizations?.TASK_COMPLETION_INCOMPLETE_LABEL ?? "Eksik Çalışmalar",
              ],
              datasets: [
                {
                  data: [
                    (
                      ((taskCompletion.completed ?? 0) * 100) /
                      ((taskCompletion.completed ?? 0) + (taskCompletion.missing ?? 0))
                    ).toFixed(1),
                    (
                      ((taskCompletion.missing ?? 0) * 100) /
                      ((taskCompletion.completed ?? 0) + (taskCompletion.missing ?? 0))
                    ).toFixed(1),
                  ],
                  backgroundColor: [
                    localizations?.TASK_COMPLETION_COMPLETED_BGCOLOR ?? "rgb(79, 118, 255)",
                    localizations?.TASK_COMPLETION_INCOMPLETE_BGCOLOR ?? "rgb(255, 41, 96)",
                  ],
                  borderColor: [
                    localizations?.TASK_COMPLETION_COMPLETED_BORDER_COLOR ??
                      "rgba(79, 118, 255, 0.5)",
                    localizations?.TASK_COMPLETION_INCOMPLETE_BORDER_COLOR ??
                      "rgba(255, 41, 96, 0.5)",
                  ],
                  borderWidth: 0,
                },
              ],
            }}
          />
        </div>
      </div>
    );
  }

  return <></>;
};

TaskCompletionSummary.defaultProps = {
  localizations: null,
};

TaskCompletionSummary.propTypes = {
  taskCompletion: PropTypes.objectOf(PropTypes.any).isRequired,
  localizations: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

export default connect(mapStateToProps, null)(TaskCompletionSummary);
