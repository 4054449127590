import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Grid } from "@mui/material";
import { FunnelSimple } from "phosphor-react";

import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import Footer from "custom/Footer";
import Alert from "custom/Alert";
import DataTable from "custom/Tables/DataTable";
import {
  getMessageList,
  getMyStudents,
  GET_MESSAGE_LIST,
  GET_MY_STUDENTS,
} from "redux/actions/pages";
import { removeMessage } from "redux/actions/message";
import MessageTypes from "enums/message-types";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import AudioMessageCell from "../components/AudioMessageCell";
import BookCell from "../components/BookCell";
import MessageCell from "../components/MessageCell";
import StudentsCell from "../components/StudentsCell";

const MessageList = ({
  messages,
  getMessages,
  getStudents,
  apiGetStudentsErrorMsg,
  apiGetMessagesErrorMsg,
  localizations,
  removeApiErrorMsg,
  language,
  myStudents,
}) => {
  const [messageFilter, setMessageFilter] = useState("");

  useEffect(() => {
    getMessages();
    getStudents();
  }, []);

  useEffect(() => {
    if (
      apiGetStudentsErrorMsg &&
      apiGetStudentsErrorMsg.messageContent !== undefined &&
      apiGetStudentsErrorMsg.messageContent !== null &&
      apiGetStudentsErrorMsg.messageContent !== ""
    ) {
      Swal.fire({
        icon: apiGetStudentsErrorMsg.messageType || "error",
        title:
          apiGetStudentsErrorMsg.messageType === MessageTypes.success
            ? localizations?.MESSAGE_SUCCESS ?? "BAŞARILI!"
            : localizations?.MESSAGE_ERROR ?? "HATA!",
        text: apiGetStudentsErrorMsg.messageContent,
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_CONFIRM_BTN ?? `Tamam`,
      }).then(() => {
        removeApiErrorMsg(GET_MY_STUDENTS);
      });
    }

    if (
      apiGetMessagesErrorMsg &&
      apiGetMessagesErrorMsg.messageContent !== undefined &&
      apiGetMessagesErrorMsg.messageContent !== null &&
      apiGetMessagesErrorMsg.messageContent !== ""
    ) {
      Swal.fire({
        icon: apiGetMessagesErrorMsg.messageType || "error",
        title:
          apiGetMessagesErrorMsg.messageType === MessageTypes.success
            ? localizations?.MESSAGE_SUCCESS ?? "BAŞARILI!"
            : localizations?.MESSAGE_ERROR ?? "HATA!",
        text: apiGetMessagesErrorMsg.messageContent,
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_CONFIRM_BTN ?? `Tamam`,
      }).then(() => {
        removeApiErrorMsg(GET_MESSAGE_LIST);
      });
    }
    // eslint-disable-next-line
  }, [apiGetStudentsErrorMsg, apiGetMessagesErrorMsg]);

  const columns = [
    {
      Header: localizations?.DATE ?? "Tarih",
      accessor: "date",
    },
    {
      Header: localizations?.MESSAGE ?? "Mesaj",
      accessor: "message",
      width: "30%",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => <MessageCell {...data} />,
    },
    {
      Header: localizations?.AUDIO_MESSAGE ?? "Sesli Mesaj",
      accessor: "audioMessage",
      align: "center",
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: [data] }) => <AudioMessageCell {...data} />,
    },
    {
      Header: localizations?.BOOK ?? "Kitap",
      accessor: "book",
      width: "20%",
      // eslint-disable-next-line
      Cell: ({ value: [data] }) => <BookCell data={data} />,
    },
    {
      Header: localizations?.STUDENTS ?? "Alıcılar",
      accessor: "students",
      // eslint-disable-next-line
      Cell: ({ value: [data] }) => <StudentsCell {...data} />,
    },
  ];

  const getDataTableData = (messageList, filter, lang) => ({
    columns,
    rows:
      messageList && messageList.length > 0
        ? messageList
            .filter((m) => {
              let lowercaseFilter = "";
              if (filter && filter !== "") {
                lowercaseFilter = filter.toLocaleLowerCase(lang);
              }

              return (
                m.message?.toLocaleLowerCase(lang)?.includes(lowercaseFilter) ||
                m.book?.title?.toLocaleLowerCase(lang)?.includes(lowercaseFilter) ||
                m.users
                  ?.map((uId) => {
                    const student = myStudents.find((ms) => ms.id === uId);

                    if (student) {
                      return `${student.name} ${student.surname}`.toLocaleLowerCase(lang);
                    }

                    return "";
                  })
                  ?.some((st) => st.includes(lowercaseFilter))
              );
            })
            .sort((a, b) => {
              const [bDate, bTime] = b.creationDate.split(" ");
              const newB = `${bDate.split(".").reverse().join("")}${bTime.replace(":", "")}`;

              const [aDate, aTime] = a.creationDate.split(" ");
              const newA = `${aDate.split(".").reverse().join("")}${aTime.replace(":", "")}`;
              return newB - newA;
            })
            .map((m) => ({
              date: m.creationDate?.split(" ")[0],
              message: [{ content: m.message }],
              audioMessage: [{ url: m.audioMessageUrl }],
              book: [
                { title: m.book?.title, coverImage: m.book?.coverImage, fontWeight: "medium" },
              ],
              students: [{ studentIds: m.users }],
            }))
        : [],
  });

  const memoizedTableData = useMemo(
    () => getDataTableData(messages, messageFilter, language),
    [messages, messageFilter, language]
  );

  return (
    <>
      <Alert />
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container>
            <Grid item sm={7} xs={12}>
              <h3>{localizations?.MESSAGES_HEADER}</h3>
            </Grid>
            <Grid item sm={5} xs={12}>
              <SuiInput
                size="small"
                type="search"
                placeholder={localizations?.MESSAGES_FILTER_INPUT_PLACEHOLDER}
                icon={{ component: <FunnelSimple />, direction: "left" }}
                value={messageFilter}
                onChange={(e) => {
                  setMessageFilter(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item lg={12} mt={3} xs={12}>
            <DataTable
              isSorted={false}
              table={memoizedTableData}
              entriesPerPage={{
                defaultValue: 10,
                entries: [5, 10, 15, 20, 25],
              }}
              // canSearch
              pagination={{ variant: "contained", color: "dark" }}
            />
          </Grid>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

MessageList.defaultProps = {
  messages: [],
  apiGetStudentsErrorMsg: null,
  apiGetMessagesErrorMsg: null,
  localizations: null,
  language: "TR",
  myStudents: [],
};

MessageList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.any),
  getMessages: PropTypes.func.isRequired,
  getStudents: PropTypes.func.isRequired,
  removeApiErrorMsg: PropTypes.func.isRequired,
  apiGetStudentsErrorMsg: PropTypes.objectOf(PropTypes.any),
  apiGetMessagesErrorMsg: PropTypes.objectOf(PropTypes.any),
  localizations: PropTypes.objectOf(PropTypes.any),
  language: PropTypes.string,
  myStudents: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

const mapStateToProps = (state) => ({
  messages: state.data.messages ?? [],
  apiGetStudentsErrorMsg: state.message[GET_MY_STUDENTS],
  apiGetMessagesErrorMsg: state.message[GET_MESSAGE_LIST],
  localizations: state.localization?.pairs,
  language: state.localization?.language ?? "TR",
  myStudents: state.data.students ?? [],
});

const mapDispatchToProps = {
  getMessages: getMessageList,
  getStudents: getMyStudents,
  removeApiErrorMsg: removeMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageList);
