/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { jsPDF as JsPdf } from "jspdf";
import html2canvas from "html2canvas";
import Switch from "@mui/material/Switch";

import Loading from "custom/Loading";
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import { Card, Grid } from "@mui/material";
import ReportHeader from "layouts/reports/components/ReportHeader";
import ClassGeneralInfo from "layouts/reports/components/ClassGeneralInfo";
import MonthlyTaskComparisonChart from "layouts/reports/components/MonthlyTaskComparisonChart";
import MonthlyReadingTaskIncentiveOrNotComparison from "layouts/reports/components/MonthlyReadingTaskIncentiveOrNotComparison";
import TaskCompletionSummary from "layouts/reports/components/TaskCompletionSummary";
import QuizResults from "layouts/reports/components/QuizResults";
import Footer from "custom/Footer";
import { connect } from "react-redux";
import { getGeneralSchoolClassReports } from "redux/actions/pages/reports";
import { getAcademicStartDate } from "layouts/reports/components/month.mapper";
import "./_school-class-reports.css";

const PageOrientationType = Object.freeze({
  portrait: "portrait",
  landscape: "landscape",
});

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const SchoolClassReports = ({ getReports, generalSClassReports, localizations }) => {
  const location = useLocation();
  const pdfContainerRef = useRef();

  const [showAverages, setShowAverages] = useState(true);
  const [selectedClassId, setSelectedClassId] = useState(0);
  const [selectedClass, setSelectedClass] = useState(null);

  useEffect(() => {
    // eslint-disable-next-line no-console
    getReports({ academicStartDate: getAcademicStartDate() });
  }, []);

  useEffect(() => {
    if (generalSClassReports?.length > 0) {
      if (location.state?.classId > 0 && selectedClassId === 0) {
        setSelectedClassId(parseInt(location.state?.classId, 10) ?? generalSClassReports[0].id);
      } else {
        setSelectedClassId(generalSClassReports[0].id);
      }
    }
  }, [generalSClassReports]);

  useEffect(() => {
    if (selectedClassId > 0) {
      setSelectedClass(generalSClassReports.find((cl) => cl.id === selectedClassId));
    }
  }, [selectedClassId]);

  const downloadPDFReport = async (e) => {
    e.preventDefault();
    const element = pdfContainerRef.current;
    element.style.display = "block";

    await delay(2000);

    const { clientHeight, clientWidth } = element;

    const canvas = await html2canvas(element, { scale: 1 });
    const data = canvas.toDataURL("image/jpeg", 100);

    const pageOrientation = PageOrientationType.portrait;

    const pdf = new JsPdf({ orientation: pageOrientation });

    const originalPdfWidth = pdf.internal.pageSize.getWidth();

    let pdfWidth = 0;
    let pdfHeight = 0;

    // calculate belongs to width
    pdfWidth = originalPdfWidth;
    pdfHeight = (originalPdfWidth * clientHeight) / clientWidth;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("print.pdf");
    element.style.display = "none";
  };

  return (
    <>
      <Loading />
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container>
            <Grid item sm={7} xs={12} mb={{ xs: 2, sm: 0 }}>
              <h3>Sınıf Raporu</h3>
            </Grid>
            <Grid
              item
              size={{ sm: 5, xs: 12 }}
              flex={{ sm: true, xs: false }}
              ml={{ sm: "auto", xs: 0 }}
            >
              <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                {location?.state?.totalMonthlyReadingCounts?.length > 0 ? (
                  <SuiBox display="flex" mr={3}>
                    <SuiBox>
                      <Switch
                        checked={showAverages}
                        onChange={() => setShowAverages(!showAverages)}
                      />
                    </SuiBox>
                    <SuiBox width="80%" ml={1}>
                      <SuiTypography variant="button" fontWeight="regular" color="text">
                        Ortalama Göster
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                ) : null}
                <SuiButton
                  size="medium"
                  variant="contained"
                  color="error"
                  onClick={downloadPDFReport}
                >
                  {localizations?.REPORTS_DOWNLOAD_PDF_BTN_LABEL ?? "PDF İndir"}
                </SuiButton>
              </div>
            </Grid>
            {selectedClass !== null && selectedClass !== undefined ? (
              <Grid item xs={12} mt={3} xl={12}>
                <Card sx={{ overflow: "visible" }}>
                  <div className="class-reports-container">
                    <SuiBox pt={3} px={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} xl={12}>
                          <ReportHeader />
                        </Grid>
                        <Grid item xs={12} xl={12}>
                          <div className="class-reports-line" />
                        </Grid>
                        <Grid item xs={12} xl={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={4}>
                              <SuiSelect
                                willMaxHeightBeUsed
                                onChange={(selected) => setSelectedClassId(selected.value)}
                                value={
                                  selectedClassId > 0
                                    ? generalSClassReports
                                        ?.map((cl) => ({
                                          value: cl.id,
                                          label: `${cl.className} Sınıfı`,
                                        }))
                                        ?.find((cl) => cl.value === selectedClassId)
                                    : null
                                }
                                placeholder="Sınıf Seçiniz"
                                options={generalSClassReports?.map((cl) => ({
                                  value: cl.id,
                                  label: `${cl.className} Sınıfı`,
                                }))}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} xl={12}>
                          <ClassGeneralInfo
                            studentCount={selectedClass?.studentCount}
                            readingCount={
                              selectedClass?.generalInformation?.reduce((total, current) => {
                                if (current.type === "READ") {
                                  return total + current.count;
                                }
                                return total;
                              }, 0) ?? 0
                            }
                            listeningCount={
                              selectedClass?.generalInformation?.reduce((total, current) => {
                                if (current.type === "LISTEN") {
                                  return total + current.count;
                                }
                                return total;
                              }, 0) ?? 0
                            }
                            recordingCount={
                              selectedClass?.generalInformation?.reduce((total, current) => {
                                if (current.type === "RECORD") {
                                  return total + current.count;
                                }
                                return total;
                              }, 0) ?? 0
                            }
                            quizCount={
                              selectedClass?.generalInformation?.reduce((total, current) => {
                                if (current.type === "QUIZ") {
                                  return total + current.count;
                                }
                                return total;
                              }, 0) ?? 0
                            }
                          />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                          <MonthlyTaskComparisonChart
                            monthlyResults={selectedClass?.monthlyResults}
                            totalClassMonthlyResults={
                              showAverages
                                ? location?.state?.totalMonthlyReadingCounts ?? null
                                : null
                            }
                            itemCount={location?.state?.classCount ?? 0}
                          />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                          <MonthlyReadingTaskIncentiveOrNotComparison
                            monthlyResults={selectedClass?.monthlyResults}
                          />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                          <TaskCompletionSummary taskCompletion={selectedClass?.taskCompletion} />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                          <QuizResults quizResults={selectedClass?.quizInformation[0]} />
                        </Grid>
                      </Grid>
                    </SuiBox>
                  </div>
                </Card>
              </Grid>
            ) : null}
          </Grid>
        </SuiBox>
        <Footer />
      </DashboardLayout>

      {/* This part will be created dynamically, after saving pdf this part will be hidden */}
      {selectedClass !== null && selectedClass !== undefined ? (
        <div className="class-reports-container-to-pdf" id="to-pdf" ref={pdfContainerRef}>
          <ReportHeader classInformation={selectedClass?.className} />
          <div className="class-reports-line" />
          <ClassGeneralInfo
            studentCount={selectedClass?.studentCount}
            readingCount={
              selectedClass?.generalInformation?.reduce((total, current) => {
                if (current.type === "READ") {
                  return total + current.count;
                }
                return total;
              }, 0) ?? 0
            }
            listeningCount={
              selectedClass?.generalInformation?.reduce((total, current) => {
                if (current.type === "LISTEN") {
                  return total + current.count;
                }
                return total;
              }, 0) ?? 0
            }
            recordingCount={
              selectedClass?.generalInformation?.reduce((total, current) => {
                if (current.type === "RECORD") {
                  return total + current.count;
                }
                return total;
              }, 0) ?? 0
            }
            quizCount={
              selectedClass?.generalInformation?.reduce((total, current) => {
                if (current.type === "QUIZ") {
                  return total + current.count;
                }
                return total;
              }, 0) ?? 0
            }
          />

          <div className="charts-container">
            <MonthlyTaskComparisonChart
              monthlyResults={selectedClass?.monthlyResults}
              totalClassMonthlyResults={
                showAverages ? location?.state?.totalMonthlyReadingCounts ?? null : null
              }
              itemCount={location?.state?.classCount ?? 0}
            />
            <MonthlyReadingTaskIncentiveOrNotComparison
              monthlyResults={selectedClass?.monthlyResults}
            />
            <TaskCompletionSummary taskCompletion={selectedClass?.taskCompletion} />
            <QuizResults quizResults={selectedClass?.quizInformation[0]} />
          </div>
        </div>
      ) : null}
    </>
  );
};

SchoolClassReports.defaultProps = {
  generalSClassReports: [],
  localizations: null,
};

SchoolClassReports.propTypes = {
  getReports: PropTypes.func.isRequired,
  generalSClassReports: PropTypes.arrayOf(PropTypes.any),
  localizations: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  generalSClassReports: state.data.generalSchoolClassReports,
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  getReports: getGeneralSchoolClassReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchoolClassReports);
