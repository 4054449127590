/* eslint-disable no-console */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useRef } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import Footer from "custom/Footer";

import requireAuth from "hocs/requireAuth";
import { connect } from "react-redux";
import { startHomePage, START_HOME_PAGE, getHomeAssignmentDetails } from "redux/actions/pages";
import { CaretLeft, CaretRight, Plus, CheckCircle, XCircle, Envelope } from "phosphor-react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { removeMessage } from "redux/actions/message";
import MessageTypes from "enums/message-types";
import Table from "custom/Tables/Table";
import SuiButton from "components/SuiButton";
import "./_home.css";
import FeedbackMessageDialog from "custom/FeedbackMessageDialog";
import RecordTableCell from "custom/RecordTableCell";

function Home({
  startScreen,
  message,
  removeNotificationMsg,
  localizations,
  allAssignments,
  getDetails,
}) {
  const history = useHistory();

  const [distance, setDistance] = useState(0);
  const [selectedAssignmentId, setSelectedAssignmentId] = useState(0);
  const [selectedAssignment, setSelectedAssignment] = useState(null);

  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageModalOpenedForUser, setMessageModalOpenedForUser] = useState(null);

  const sliderRef = useRef(null);

  const handleNext = () => {
    setDistance(distance + 170);
  };

  const handlePrev = () => {
    if (distance <= 170) {
      setDistance(0);
    } else {
      setDistance(distance - 170);
    }
  };

  const onAssignmentClick = (id) => {
    setSelectedAssignmentId(id);
  };

  const onFeedbackClick = (userId) => {
    setMessageModalOpenedForUser(userId);
    setMessageModalOpen(true);
  };

  const getTaskCompletion = (user, taskType) => {
    const task = user.tasks.find((t) => t.taskType === taskType);
    if (!task) {
      return "N/A";
    }

    if (task.completed) {
      if (taskType === "RECORD") {
        return <RecordTableCell audioUrl={task.audioUrl} />;
      }
      return <CheckCircle size={28} color="#82D616" weight="duotone" />;
    }

    return <XCircle size={28} color="#3A416F" />;
  };

  useEffect(() => {
    startScreen();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      message &&
      message.messageContent !== undefined &&
      message.messageContent !== null &&
      message.messageContent !== ""
    ) {
      Swal.fire({
        icon: message.messageType || "error",
        title:
          message.messageType === MessageTypes.success
            ? localizations?.MESSAGE_SUCCESS
            : localizations?.MESSAGE_ERROR,
        text: message.messageContent,
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_GO_HOME_CONFIRM_BTN,
      }).then(() => {
        removeNotificationMsg(START_HOME_PAGE);
        history.push("/");
      });
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    if (selectedAssignmentId > 0) {
      const currentAssignment = allAssignments.find((ass) => ass.id === selectedAssignmentId);

      if (currentAssignment && (!currentAssignment.users || currentAssignment.users.length === 0)) {
        getDetails({ assignmentId: selectedAssignmentId });
      }
    }
  }, [selectedAssignmentId]);

  useEffect(() => {
    setSelectedAssignment(allAssignments?.find((ass) => ass.id === selectedAssignmentId));
  }, [allAssignments?.find((ass) => ass.id === selectedAssignmentId)?.users]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <Grid container>
          <Grid item lg={12}>
            <h3>{localizations?.ASSIGNMENTS}</h3>
          </Grid>
          <Grid item xs={12} lg={12} mt={3}>
            <div className="assignments">
              {distance > 0 ? (
                <div className="assignment-btn prev" onClick={handlePrev}>
                  <CaretLeft size={30} color="#67748E" />
                </div>
              ) : null}
              <div className="assignments-slider-wrapper">
                <div className="assignments-slider" ref={sliderRef}>
                  <div
                    className="assignments-slider__contaier"
                    style={{
                      transform: `translate3d(-${distance}px, 0, 0)`,
                    }}
                  >
                    <div
                      className="new-item"
                      aria-hidden
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/assignment/new");
                      }}
                    >
                      <Plus size={60} color="#67748E" />
                    </div>
                    {allAssignments && allAssignments.length > 0
                      ? allAssignments.map((assignment) => {
                          const today = new Date();
                          const todayAsNumber = parseInt(
                            `${today.getFullYear()}${
                              today.getMonth() + 1 < 10
                                ? `0${today.getMonth() + 1}`
                                : today.getMonth() + 1
                            }${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`,
                            10
                          );

                          return (
                            <div
                              className="assignments-item"
                              key={assignment.id}
                              style={{
                                border:
                                  selectedAssignmentId === assignment.id
                                    ? "5px solid #82D616"
                                    : "none",
                              }}
                              onClick={() => onAssignmentClick(assignment.id)}
                            >
                              <img
                                src={assignment.book.coverImage}
                                alt=""
                                style={{
                                  filter:
                                    parseInt(assignment.dueDate.split(".").reverse().join(""), 10) <
                                    todayAsNumber
                                      ? "grayscale(1)"
                                      : "none",
                                }}
                              />
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
              {distance < (allAssignments.length + 1) * 170 - sliderRef?.current?.clientWidth ? (
                <div className="assignment-btn next" onClick={handleNext}>
                  <CaretRight color="#67748E" size={30} />
                </div>
              ) : null}
            </div>
          </Grid>
        </Grid>
        {selectedAssignment?.users?.length > 0 ? (
          <Grid container py={5}>
            <Grid item lg={12}>
              <h3>{localizations?.ASSIGNMENT_STATUS_TABLE_HEADER}</h3>
            </Grid>
            <Grid item lg={12} mt={3} xs={12}>
              <Table
                columns={[
                  { name: "name", align: "left", title: localizations?.TABLE_HEADER_NAME },
                  { name: "read", align: "center", title: localizations?.TABLE_HEADER_READING },
                  { name: "listen", align: "center", title: localizations?.TABLE_HEADER_LISTENING },
                  { name: "record", align: "center", title: localizations?.TABLE_HEADER_RECORDING },
                  { name: "quiz", align: "center", title: localizations?.TABLE_HEADER_QUIZ },
                  {
                    name: "material",
                    align: "center",
                    title: localizations?.TABLE_HEADER_MATERIAL,
                  },
                  {
                    name: "feedback",
                    align: "center",
                    title: localizations?.TABLE_HEADER_FEEDBACK,
                  },
                ]}
                rows={selectedAssignment?.users.map((user) => ({
                  name: [user.avatarUrl, user.name],
                  read: getTaskCompletion(user, "READ"),
                  listen: getTaskCompletion(user, "LISTEN"),
                  record: getTaskCompletion(user, "RECORD"),
                  quiz: getTaskCompletion(user, "QUIZ"),
                  material: getTaskCompletion(user, "MATERIAL"),
                  feedback: localizations?.FEEDBACK_BTN ? (
                    <SuiButton circular color="light" onClick={() => onFeedbackClick(user.id)}>
                      {localizations?.FEEDBACK_BTN}
                    </SuiButton>
                  ) : (
                    <SuiButton
                      iconOnly
                      circular
                      color="light"
                      onClick={() => onFeedbackClick(user.id)}
                    >
                      <Envelope size={28} weight="duotone" />
                    </SuiButton>
                  ),
                }))}
              />
            </Grid>
          </Grid>
        ) : null}
      </SuiBox>
      <FeedbackMessageDialog
        onClose={() => {
          setMessageModalOpen(false);
          setMessageModalOpenedForUser(null);
        }}
        open={messageModalOpen}
        messages={
          selectedAssignment?.users?.find((us) => us.id === messageModalOpenedForUser)?.messages ??
          []
        }
        userId={messageModalOpenedForUser}
        assignmentId={selectedAssignment?.id}
      />
      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  // allBooks: state.data.books ? Object.values(state.data.books).filter((b) => b.isPublished) : [],
  message: state.message[START_HOME_PAGE],
  localizations: state.localization?.pairs,
  allAssignments:
    state.data.assignments !== undefined && state.data.assignments !== null
      ? Object.values(state.data.assignments).sort(
          (a, b) =>
            parseInt(b.dueDate.split(".").reverse().join(""), 10) -
            parseInt(a.dueDate.split(".").reverse().join(""), 10)
        )
      : [],
});

const mapDispatchToProps = {
  startScreen: startHomePage,
  removeNotificationMsg: removeMessage,
  getDetails: getHomeAssignmentDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(requireAuth(Home));
