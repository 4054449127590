/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import SuiButton from "components/SuiButton";
import {
  BookOpen,
  FolderOpen,
  Microphone,
  Play,
  Question,
  FunnelSimple,
  MagnifyingGlass,
  Record,
  Check,
  X,
  FileSearch,
} from "phosphor-react";
import Swal from "sweetalert2";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import SuiDatePicker from "components/SuiDatePicker";
import language from "flatpickr/dist/l10n/tr";
import SuiInput from "components/SuiInput";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import CustomSuiSelect from "custom/CustomSuiSelect";
import { connect } from "react-redux";
import ReactSearchBox from "react-search-box";
import ReactAudioPlayer from "react-audio-player";

import useAudio from "hooks/use-audio";
import MessageTypes from "enums/message-types";
import {
  SAVE_ASSIGNMENT,
  getAssignmentStudents,
  getAudioUploadParams,
  removeAudioUploadParams,
  saveAssignment,
  updateAssignment,
  startAddOrEditAssignment,
  saveUnsavedAssignment,
  getQuizListOfBook,
  getMaterialListOfBook,
  getQuizDetail,
} from "redux/actions/pages";
import { removeMessage } from "redux/actions/message";
import automaticMessages from "hardcoded/automatic-messages";
import QuizPreviewDialog from "../QuizPreviewDialog";
import "./_add-or-edit-assignment.css";

const AddOrEditAssignment = ({
  assignment,
  allBooks,
  localizations,
  getMyStudents,
  myStudents,
  lang,
  getAudioUploadingParameters,
  uploadParameters,
  removeAudioUploadingParameters,
  saveNewAssignment,
  redirectTo,
  startPage,
  apiErrorMsg,
  removeApiErrorMsg,
  saveUnsavedData,
  getQuizList,
  getMaterialList,
  bookQuizList,
  bookMaterialList,
  getPreviewQuiz,
  previewQuiz,
  updateExistingAssignment,
  bookId,
}) => {
  const history = useHistory();

  const initialSelectedTaskTypes = {
    reading: false,
    listening: false,
    recording: false,
    quiz: false,
    material: false,
  };

  const [siblingSize, setSiblingSize] = useState(0);
  const [selectedBookId, setSelectedBookId] = useState(0);
  const [selectedBook, setSelectedBook] = useState(null);
  const [selectedTaskTypes, setSelectedTaskTypes] = useState(initialSelectedTaskTypes);
  const [selectedMaterialIds, setSelectedMaterialIds] = useState([]);
  const [selectedQuizIds, setSelectedQuizIds] = useState([]);
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);
  const [termin, setTermin] = useState(null);
  const [message, setMessage] = useState("");
  const [studentFilter, setStudentFilter] = useState("");
  const [selectAllStudents, setSelectAllStudents] = useState(false);
  const [filteredStudentList, setFilteredStudentList] = useState([]);
  const [audioMessageDoUrl, setAudioMessageDoUrl] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [previewQuizId, setPreviewQuizId] = useState(null);
  // const [isRecording, setIsRecording] = useState(false);
  // const [showRecordModal, setShowRecordModal] = useState(false);

  const siblingRef = useRef(null);

  const [audioUrl, isRecording, startRecording, stopRecording, audioData, removeAudioData] =
    useAudio({
      MESSAGE_ERROR: localizations?.MESSAGE_ERROR,
      MESSAGE_START_AUDIO_ERROR_TEXT: localizations?.MESSAGE_START_AUDIO_ERROR_TEXT,
      MESSAGE_CONFIRM_BTN: localizations?.MESSAGE_CONFIRM_BTN,
    });

  const updateSize = () => {
    if (siblingRef.current?.clientHeight > 0) {
      setSiblingSize(siblingRef.current?.clientHeight);
    }
  };

  useEffect(() => {
    if (redirectTo && redirectTo !== "" && redirectTo !== "/assignment/new") {
      history.push(redirectTo);
    }
  }, [redirectTo]);

  useEffect(() => {
    if (
      apiErrorMsg &&
      apiErrorMsg.messageContent !== undefined &&
      apiErrorMsg.messageContent !== null &&
      apiErrorMsg.messageContent !== ""
    ) {
      Swal.fire({
        icon: apiErrorMsg.messageType || "error",
        title:
          apiErrorMsg.messageType === MessageTypes.success
            ? localizations?.MESSAGE_SUCCESS ?? "BAŞARILI!"
            : localizations?.MESSAGE_ERROR ?? "HATA!",
        text: apiErrorMsg.messageContent,
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_CONFIRM_BTN ?? `Tamam`,
      }).then(() => {
        removeApiErrorMsg(SAVE_ASSIGNMENT);
        // history.push("/home");
      });
    }
    // eslint-disable-next-line
  }, [apiErrorMsg]);

  useEffect(() => {
    // Prepare the page.. (Redirection removed if coming here with redirection.)
    startPage();

    // REDUX -> getStudentsStudents (GET https://okumaplatformu.com/api/class-user/get-my-students)
    getMyStudents();

    // REDUX -> getAssignmentWithId(id); --> CurrentAssignment (STATE)
    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (assignment && assignment.bookId > 0) {
      setSelectedBookId(assignment.bookId);
      setSelectedTaskTypes({
        reading: assignment.read,
        listening: assignment.listen,
        recording: assignment.record,
        quiz: assignment.quiz,
        material: assignment.material,
      });

      if (assignment.tasks && assignment.tasks.length > 0) {
        setSelectedQuizIds(
          assignment?.tasks?.filter((t) => t.taskType === "QUIZ")?.map((qt) => qt.element?.id) ?? []
        );
        setSelectedMaterialIds(
          assignment?.tasks
            ?.filter((t) => t.taskType === "MATERIAL")
            ?.map((mt) => mt.element?.id) ?? []
        );
      }
      setTermin(assignment.dueDate);
      setMessage(assignment.message);
      setAudioMessageDoUrl(assignment.audioMessageUrl);

      if (assignment.users && assignment.users.length > 0) {
        setSelectedStudentIds(assignment.users);
      }

      return;
    }

    if (bookId > 0) {
      setSelectedBookId(bookId);
    }
  }, [assignment]);

  useEffect(() => {
    updateSize();
  }, [
    selectedTaskTypes.quiz,
    selectedTaskTypes.material,
    filteredStudentList,
    selectedBookId,
    selectedBook,
  ]);

  useEffect(() => {
    if (myStudents.length > 0) {
      if (studentFilter === "") {
        setFilteredStudentList(myStudents);
      } else {
        const lowerStudentFilter = studentFilter.toLowerCase();
        const newStudentList = myStudents.filter(
          (s) =>
            s.name?.toLowerCase().includes(lowerStudentFilter) ||
            s.surname?.toLowerCase().includes(lowerStudentFilter) ||
            s.nickName?.toLowerCase().includes(lowerStudentFilter) ||
            s.studentNo?.toString().includes(studentFilter) ||
            s.className?.toLowerCase().includes(lowerStudentFilter) ||
            s.groups
              .map((g) => g.key)
              .join(", ")
              .toLocaleLowerCase(lang)
              // .toLowerCase()
              .includes(lowerStudentFilter)
        );

        setFilteredStudentList(newStudentList);
      }
    }
  }, [studentFilter, myStudents]);

  useEffect(() => {
    const result = filteredStudentList
      .map((fs) => fs.id)
      .every((el) => selectedStudentIds.indexOf(el) !== -1);
    setSelectAllStudents(result);
  }, [selectedStudentIds, filteredStudentList]);

  useEffect(() => {
    if (allBooks.length > 0 && selectedBookId > 0) {
      const book = allBooks.find((b) => b.id === selectedBookId);

      if (book) {
        setSelectedBook(book);
      }
    }
  }, [selectedBookId, allBooks]);

  useEffect(() => {
    if (selectedBookId && selectedBookId > 0) {
      // Selected task types initialized for all task types
      setSelectedTaskTypes(initialSelectedTaskTypes);
      setSelectedQuizIds([]);
      setSelectedMaterialIds([]);

      if (!bookQuizList || bookQuizList.bookId !== selectedBookId) {
        getQuizList({ bookId: selectedBookId });
      }
      if (!bookMaterialList || bookMaterialList.bookId !== selectedBookId) {
        getMaterialList({ bookId: selectedBookId });
      }
    }
  }, [selectedBookId]);

  useEffect(() => {
    if (previewQuizId && previewQuizId > 0) {
      getPreviewQuiz({ quizId: previewQuizId });
    }
  }, [previewQuizId]);

  useEffect(() => {
    if (
      uploadParameters &&
      uploadParameters.key &&
      uploadParameters.key !== "" &&
      uploadParameters.url &&
      uploadParameters.url !== ""
    ) {
      const { key, url } = uploadParameters;
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", `${url}`);
      xhr.setRequestHeader("Content-Type", "audio/mpeg");
      xhr.setRequestHeader("x-amz-acl", "public-read");
      xhr.send(audioData);
      xhr.onload = (e) => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const edictSoundUrl = `https://edictdigitalspaces.fra1.digitaloceanspaces.com/${key}`;
            setAudioMessageDoUrl(edictSoundUrl);
          } else {
            // eslint-disable-next-line no-console
            console.error(xhr.statusText);
          }
        }
      };
      xhr.onerror = (e) => {
        // eslint-disable-next-line no-console
        console.error("on sound upload error");
        // eslint-disable-next-line no-console
        console.error(xhr.statusText);
      };
      xhr.onloadend = (ev) => {};
    }
  }, [uploadParameters]);

  const onReadingCheckboxClicked = (e) => {
    e.preventDefault();
    setSelectedTaskTypes({
      ...selectedTaskTypes,
      reading: !selectedTaskTypes.reading,
    });
  };

  const onListeningCheckboxClicked = (e) => {
    e.preventDefault();
    setSelectedTaskTypes({
      ...selectedTaskTypes,
      listening: !selectedTaskTypes.listening,
    });
  };

  const onRecordingCheckboxClicked = (e) => {
    e.preventDefault();
    setSelectedTaskTypes({
      ...selectedTaskTypes,
      recording: !selectedTaskTypes.recording,
    });
  };

  const onQuizCheckboxClicked = (e) => {
    e.preventDefault();
    setSelectedTaskTypes({
      ...selectedTaskTypes,
      quiz: !selectedTaskTypes.quiz,
    });
  };

  const onMaterialCheckboxClicked = (e) => {
    e.preventDefault();
    setSelectedTaskTypes({
      ...selectedTaskTypes,
      material: !selectedTaskTypes.material,
    });
  };

  const handleUploadSound = () => {
    const fileType = "audio/mpeg";
    const extention = "mp3";
    const folderName = "usersoundfiles";
    getAudioUploadingParameters({ type: fileType, folder: folderName, extention });
  };

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xl={12} xs={12}>
          <h3>
            {assignment && assignment.assignmentId && assignment.assignmentId > 0
              ? localizations?.EDIT_ASSIGNMENT_HEADER ?? "Görev Düzenle"
              : localizations?.NEW_ASSIGNMENT_HEADER ?? "Yeni Görev"}
          </h3>
        </Grid>
        <Grid item lg={7} xs={12}>
          <Grid container spacing={5} ref={siblingRef}>
            <Grid item xs={12}>
              <Grid container flexWrap="wrap" alignItems="center">
                <Grid item lg={6} xs={6}>
                  <h3 className="assignment-sub-header">{localizations?.BOOK}</h3>
                </Grid>
                {assignment && assignment.assignmentId && assignment.assignmentId > 0 ? null : (
                  <Grid item lg={6} xs={6} style={{ minWidth: "200px" }}>
                    <div className="searchbox-wrapper">
                      <div className="searchbox-container">
                        <ReactSearchBox
                          data={allBooks.map((book) => ({ key: book.id, value: book.title }))}
                          placeholder={
                            localizations?.ASSIGNMENT_SELECT_WITH_BOOK_PLACEHOLDER ??
                            "Kitap adıyla seçiniz"
                          }
                          onSelect={(record) => {
                            if (record.item?.key) {
                              setSelectedBookId(record.item?.key);
                            }
                          }}
                          autoFocus
                          leftIcon={<MagnifyingGlass size={20} weight="duotone" color="#344767" />}
                          iconBoxSize="40px"
                        />
                      </div>
                    </div>
                  </Grid>
                )}
                {selectedBook !== null && selectedBook !== undefined ? (
                  <Grid item xs={12} lg={12} mt={3}>
                    <div className="book-detail-container">
                      <div className="book-cover">
                        <img src={selectedBook.coverImage} alt="" />
                      </div>
                      <div className="book-info">
                        <SuiTypography variant="h5">{selectedBook.title}</SuiTypography>
                        <div>
                          <SuiTypography
                            variant="button"
                            fontWeight="light"
                            fontSize="14"
                            fontStyle="italic"
                          >
                            {selectedBook.generalInfo}
                          </SuiTypography>
                        </div>
                        <div>
                          <SuiTypography
                            variant="button"
                            fontWeight="light"
                            style={{ fontSize: "11px" }}
                          >
                            {`${localizations?.PAGE ?? "Sayfa"}(${selectedBook.pageCount}) / ${
                              localizations?.WORD ?? "Kelime"
                            }(${selectedBook.wordCount})`}
                          </SuiTypography>
                        </div>
                        <div>
                          <SuiTypography
                            variant="button"
                            fontWeight="light"
                            style={{ fontSize: "11px" }}
                          >
                            {`${localizations[selectedBook.level]}${
                              selectedBook.level && selectedBook.grade ? " / " : ""
                            }${localizations[selectedBook.grade]}`}
                          </SuiTypography>
                        </div>

                        {/* <SuiTypography variant="h6">{selectedBook.level}</SuiTypography> */}
                        <div className="book-info-buttons-container">
                          <SuiButton
                            variant="contained"
                            color="info"
                            style={{ marginRight: "24px", marginTop: "12px" }}
                            href={`https://yedek.okumaplatformu.com/read/${selectedBook.id}`}
                            target="_blank"
                          >
                            {localizations?.READ_BOOK_BTN ?? "Kitabı Oku"}
                          </SuiButton>
                          {assignment &&
                          assignment.assignmentId &&
                          assignment.assignmentId > 0 ? null : (
                            <SuiButton
                              variant="contained"
                              color="info"
                              style={{ marginTop: "12px" }}
                              onClick={(e) => {
                                e.preventDefault();
                                const unsavedAssignment = {
                                  bookId: selectedBookId,
                                  read: selectedTaskTypes.reading,
                                  listen: selectedTaskTypes.listening,
                                  record: selectedTaskTypes.recording,
                                  quiz: selectedTaskTypes.quiz,
                                  material: selectedTaskTypes.material,
                                  dueDate: termin,
                                  message,
                                  audioMessageUrl: audioMessageDoUrl,
                                  users: selectedStudentIds,
                                  tasks: [
                                    ...selectedQuizIds.map((sqi) => ({
                                      taskType: "QUIZ",
                                      element: {
                                        id: sqi,
                                      },
                                    })),
                                    ...selectedMaterialIds.map((smi) => ({
                                      taskType: "MATERIAL",
                                      element: {
                                        id: smi,
                                      },
                                    })),
                                  ],
                                };

                                removeAudioUploadingParameters();
                                // removeAudioData();
                                saveUnsavedData({ unsavedAssignment });
                                history.push({
                                  pathname: "/assignment",
                                  state: { bookTitle: selectedBook.title },
                                });
                              }}
                            >
                              {localizations?.PREVIOUS_ASSIGNMENTS_BTN ?? "Önceki Görevler"}
                            </SuiButton>
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <h3 className="assignment-sub-header">{localizations?.TASK_TYPE ?? "Görev Türü"}</h3>
              <Grid container>
                <Grid item xs={12}>
                  {/* <Grid item lg={8} md={10} sm={12}> */}
                  <div className="task-types-container" style={{ margin: "0 10px" }}>
                    <div className="checkbox-group">
                      <Tooltip title={localizations?.READING_TOOLTIP ?? "Okuma"} placement="bottom">
                        <span>
                          <SuiButton
                            onClick={onReadingCheckboxClicked}
                            className={`checkbox-label-icon ${
                              selectedTaskTypes.reading ? "blue" : "not-selected"
                            }`}
                            size="large"
                            iconOnly
                            circular
                            disabled={
                              assignment && assignment.assignmentId && assignment.assignmentId > 0
                            }
                          >
                            <BookOpen weight="duotone" color="white" />
                          </SuiButton>
                        </span>
                      </Tooltip>
                    </div>
                    <div className="checkbox-group">
                      <Tooltip
                        title={
                          selectedBook && !selectedBook.hasBookAudio
                            ? localizations?.BOOK_HAS_NO_AUDIO_TOOLTIP ??
                              "Dinleme görevi verilemez!"
                            : localizations?.LISTENING_TOOLTIP ?? "Dinleme"
                        }
                        placement="bottom"
                      >
                        <span>
                          <SuiButton
                            onClick={onListeningCheckboxClicked}
                            className={`checkbox-label-icon ${
                              selectedTaskTypes.listening ? "blue" : "not-selected"
                            }`}
                            size="large"
                            iconOnly
                            circular
                            disabled={
                              (assignment &&
                                assignment.assignmentId &&
                                assignment.assignmentId > 0) ||
                              (selectedBook && !selectedBook.hasBookAudio)
                            }
                          >
                            <Play weight="duotone" color="white" />
                          </SuiButton>
                        </span>
                      </Tooltip>
                    </div>

                    <div className="checkbox-group">
                      <Tooltip
                        title={localizations?.RECORDING_TOOLTIP ?? "Oku - Kaydet"}
                        placement="top"
                      >
                        <span>
                          <SuiButton
                            onClick={onRecordingCheckboxClicked}
                            className={`checkbox-label-icon ${
                              selectedTaskTypes.recording ? "blue" : "not-selected"
                            }`}
                            size="large"
                            iconOnly
                            circular
                            disabled={
                              assignment && assignment.assignmentId && assignment.assignmentId > 0
                            }
                          >
                            <Microphone weight="duotone" color="white" />
                          </SuiButton>
                        </span>
                      </Tooltip>
                    </div>
                    {bookQuizList &&
                    bookQuizList.bookId > 0 &&
                    bookQuizList.quizes &&
                    Object.keys(bookQuizList.quizes).length > 0 ? (
                      <div className="checkbox-group">
                        <Tooltip title={localizations?.QUIZ_TOOLTIP ?? "Quiz"} placement="top">
                          <span>
                            <SuiButton
                              onClick={onQuizCheckboxClicked}
                              className={`checkbox-label-icon ${
                                selectedTaskTypes.quiz ? "purple" : "not-selected"
                              }`}
                              size="large"
                              iconOnly
                              circular
                              disabled={
                                assignment && assignment.assignmentId && assignment.assignmentId > 0
                              }
                            >
                              <Question weight="duotone" color="white" />
                            </SuiButton>
                          </span>
                        </Tooltip>
                      </div>
                    ) : null}
                    {bookMaterialList &&
                    bookMaterialList.bookId > 0 &&
                    bookMaterialList.materials &&
                    Object.keys(bookMaterialList.materials).length > 0 ? (
                      <div className="checkbox-group">
                        <Tooltip
                          title={localizations?.MATERIAL_TOOLTIP ?? "Materyal"}
                          placement="top"
                        >
                          <span>
                            <SuiButton
                              onClick={onMaterialCheckboxClicked}
                              className={`checkbox-label-icon ${
                                selectedTaskTypes.material ? "purple" : "not-selected"
                              }`}
                              size="large"
                              iconOnly
                              circular
                              disabled={
                                assignment && assignment.assignmentId && assignment.assignmentId > 0
                              }
                            >
                              <FolderOpen weight="duotone" color="white" />
                            </SuiButton>
                          </span>
                        </Tooltip>
                      </div>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
            </Grid>

            {selectedTaskTypes.quiz || selectedTaskTypes.material ? (
              <Grid item xs={12}>
                <Grid container>
                  {selectedTaskTypes.quiz ? (
                    <Grid item lg={6} xs={6}>
                      <h3 className="assignment-sub-header">
                        {localizations?.SELECT_QUIZ ?? "Quiz Seç"}
                      </h3>
                      <div className="quiz-selection-container" style={{ margin: "0 20px" }}>
                        {bookQuizList && bookQuizList.quizes
                          ? Object.values(bookQuizList.quizes)
                              .sort((a, b) => a.quizOrder - b.quizOrder)
                              .map((quiz) => (
                                <div className="selection-item" key={`quiz-${quiz.id}`}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="secondary"
                                        disabled={
                                          assignment &&
                                          assignment.assignmentId &&
                                          assignment.assignmentId > 0
                                        }
                                        checked={selectedQuizIds.includes(quiz.id)}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setSelectedQuizIds([...selectedQuizIds, quiz.id]);
                                          } else {
                                            const filteredQuizIds = selectedQuizIds.filter(
                                              (q) => q !== quiz.id
                                            );
                                            setSelectedQuizIds(filteredQuizIds);
                                          }
                                        }}
                                      />
                                    }
                                    label={
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <p>{`${quiz.quizOrder}. ${
                                          localizations[quiz.type] ?? quiz.type
                                        }`}</p>
                                        <div
                                          style={{ marginLeft: "5px", cursor: "pointer" }}
                                          aria-hidden
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setPreviewQuizId(quiz.id);
                                            setModalOpen(true);
                                          }}
                                        >
                                          <Tooltip
                                            title={
                                              localizations?.PREVIEW_TOOLTIP ?? "Dökümana Gözat"
                                            }
                                            placement="right"
                                          >
                                            <FileSearch
                                              size={22}
                                              weight="duotone"
                                              color="#6C757D"
                                            />
                                          </Tooltip>
                                        </div>
                                      </div>
                                    }
                                  />
                                </div>
                              ))
                          : null}
                      </div>
                    </Grid>
                  ) : null}
                  {selectedTaskTypes.material ? (
                    <Grid item lg={6} xs={6}>
                      <h3 className="assignment-sub-header">
                        {localizations?.SELECT_MATERIAL ?? "Materyal Seç"}
                      </h3>
                      <div className="material-selection-container" style={{ margin: "0 20px" }}>
                        {bookMaterialList && bookMaterialList.materials
                          ? Object.values(bookMaterialList.materials)
                              .sort((a, b) => a.materialOrder - b.materialOrder)
                              .map((material) => (
                                <div className="selection-item" key={`material-${material.id}`}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="secondary"
                                        disabled={
                                          assignment &&
                                          assignment.assignmentId &&
                                          assignment.assignmentId > 0
                                        }
                                        checked={selectedMaterialIds.includes(material.id)}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setSelectedMaterialIds([
                                              ...selectedMaterialIds,
                                              material.id,
                                            ]);
                                          } else {
                                            const filteredMaterialIds = selectedMaterialIds.filter(
                                              (m) => m !== material.id
                                            );
                                            setSelectedMaterialIds(filteredMaterialIds);
                                          }
                                        }}
                                      />
                                    }
                                    label={
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <p>
                                          {`${material.materialOrder}. ${
                                            localizations[material.learningDesignType] ??
                                            material.learningDesignType
                                          }`}
                                        </p>

                                        <a
                                          href={material.fileUrl}
                                          target="_blank"
                                          style={{ marginLeft: "5px" }}
                                        >
                                          <Tooltip
                                            title={
                                              localizations?.PREVIEW_TOOLTIP ?? "Dökümana Gözat"
                                            }
                                            placement="right"
                                          >
                                            <FileSearch
                                              size={22}
                                              weight="duotone"
                                              color="#6C757D"
                                            />
                                          </Tooltip>
                                        </a>
                                      </div>
                                    }
                                  />
                                </div>
                              ))
                          : null}
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <h3 className="assignment-sub-header">
                {localizations?.ASSINMENT_DUE_DATE ?? "Görev Teslim Tarihi"}
              </h3>
              <div className="due-date-container" style={{ margin: "0 10px" }}>
                <SuiDatePicker
                  input={{ placeholder: localizations?.PICK_DATE_PLACEHOLDER ?? "Tarih seçiniz" }}
                  options={{
                    locale: "TR".toLowerCase(),
                    dateFormat: "d.m.Y",
                    enableTime: false,
                    minDate: "today",
                  }}
                  value={termin}
                  onChange={([date]) => {
                    setTermin(
                      `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}.${
                        date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
                      }.${date.getFullYear()}`
                    );
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Grid container flexWrap="wrap" alignItems="center">
                <Grid item lg={6} xs={6} mb={1}>
                  <h3 className="assignment-sub-header">{localizations?.MESSAGE ?? "Mesaj"}</h3>
                </Grid>
                <Grid item lg={6} xs={6} style={{ minWidth: "200px" }} mb={1}>
                  <div className="message-select-container">
                    <CustomSuiSelect
                      size="small"
                      onChange={(selected) => {
                        if (message === "") {
                          setMessage(`${selected.label}`);
                        } else {
                          setMessage(`${message}\n${selected.label}`);
                        }
                      }}
                      value={null}
                      placeholder={localizations?.SELECT_MESSAGE_PLACEHOLDER ?? "Mesaj Seç"}
                      options={automaticMessages}
                    />
                  </div>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <div className="message-container" style={{ margin: "0 10px" }}>
                    <SuiInput
                      placeholder={localizations?.WRITE_MESSAGE_PLACEHOLDER ?? "Mesaj yazınız..."}
                      multiline
                      rows={5}
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      style={{ paddingRight: "60px" }}
                    />
                    <div className="message-record-button">
                      {!isRecording ? (
                        <Tooltip
                          title={localizations?.RECORD_AUDIO_MSG_TOOLTIP ?? "Sesli mesaj kaydet"}
                          placement="left"
                        >
                          <Microphone
                            color="#67BCEC"
                            size={40}
                            weight="duotone"
                            onClick={(e) => {
                              e.preventDefault();
                              startRecording();
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "#DD6363",
                            width: "40px",
                            height: "40px",
                            borderRadius: "20px",
                          }}
                        >
                          <Tooltip
                            title={localizations?.PAUSE_TOOLTIP ?? "Ses kaydını durdur"}
                            placement="left"
                          >
                            <Record
                              color="white"
                              size={40}
                              weight="duotone"
                              onClick={(e) => {
                                e.preventDefault();
                                stopRecording();
                              }}
                            />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
                {audioUrl && audioUrl !== "" && audioData ? (
                  <Grid item lg={12} xs={12}>
                    <div
                      className="audio-player-container"
                      style={{ margin: "10px 10px 0 10px", display: "flex" }}
                    >
                      <ReactAudioPlayer src={audioUrl} controls style={{ width: "100%" }} />
                      <Tooltip title={localizations?.SAVE_TOOLTIP ?? "Kaydet"} placement="top">
                        <SuiButton
                          className="icon-buttons gray"
                          onClick={(e) => {
                            e.preventDefault();
                            handleUploadSound();
                          }}
                          size="large"
                          iconOnly
                          circular
                        >
                          <Check weight="bold" color="green" />
                        </SuiButton>
                      </Tooltip>
                      <Tooltip
                        title={localizations?.REMOVE_RECORD_TOOLTIP ?? "Vazgeç"}
                        placement="top"
                      >
                        <SuiButton
                          className="icon-buttons gray"
                          onClick={(e) => {
                            e.preventDefault();
                            removeAudioData();
                            setAudioMessageDoUrl("");
                          }}
                          size="large"
                          iconOnly
                          circular
                        >
                          <X weight="bold" color="red" />
                        </SuiButton>
                      </Tooltip>
                    </div>
                  </Grid>
                ) : assignment &&
                  assignment.assignmentId &&
                  assignment.assignmentId > 0 &&
                  assignment.audioMessageUrl &&
                  assignment.audioMessageUrl !== "" ? (
                  <Grid item lg={12} xs={12}>
                    <div
                      className="audio-player-container"
                      style={{ margin: "10px 10px 0 10px", display: "flex" }}
                    >
                      <ReactAudioPlayer
                        src={assignment.audioMessageUrl}
                        controls
                        style={{ width: "100%" }}
                      />
                    </div>
                  </Grid>
                ) : assignment && assignment.bookId > 0 && audioMessageDoUrl !== "" ? (
                  <Grid item lg={12} xs={12}>
                    <div
                      className="audio-player-container"
                      style={{ margin: "10px 10px 0 10px", display: "flex" }}
                    >
                      <ReactAudioPlayer
                        src={audioMessageDoUrl}
                        controls
                        style={{ width: "100%" }}
                      />
                    </div>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={5} xs={12}>
          <h3 className="assignment-sub-header">{localizations?.STUDENT ?? "Öğrenci"}</h3>
          <div className="student-list-container" style={{ margin: "0 10px" }}>
            <Card>
              {/* <Card style={{ minHeight: "400px", height: "400px" }}> */}
              <SuiBox
                py={3}
                lineHeight={1.6}
                style={{ margin: "0 1rem" }}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <div
                  className="select-all-checkbox-container"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Checkbox
                    checked={selectAllStudents}
                    disabled={assignment && assignment.assignmentId && assignment.assignmentId > 0}
                    onClick={(e) => {
                      e.preventDefault();

                      if (!selectAllStudents) {
                        // Filtredeki herşeyi select et!
                        const newSelectedList = [
                          ...selectedStudentIds,
                          ...filteredStudentList.map((fs) => fs.id),
                        ];
                        const newSelectedListWithoutDuplications = [...new Set(newSelectedList)];
                        setSelectedStudentIds(newSelectedListWithoutDuplications);
                      } else {
                        const filteredStudentIds = selectedStudentIds.filter(
                          (s) => !filteredStudentList.map((fs) => fs.id).includes(s)
                        );
                        setSelectedStudentIds(filteredStudentIds);
                      }
                    }}
                  />
                  <SuiTypography variant="button" fontWeight="regular" color="text" ml={1}>
                    {localizations?.SELECT_ALL ?? "Hepsini Seç"}
                  </SuiTypography>
                </div>

                <SuiBox pr={1}>
                  <SuiInput
                    size="small"
                    type="search"
                    placeholder={
                      localizations?.STUDENTS_FILTER_INPUT_PLACEHOLDER ?? "Öğrenci Filtrele"
                    }
                    icon={{ component: <FunnelSimple />, direction: "left" }}
                    value={studentFilter}
                    onChange={(e) => {
                      setStudentFilter(e.target.value);
                    }}
                  />
                </SuiBox>
              </SuiBox>
              <div
                style={{
                  overflow: "scroll",
                  maxHeight: siblingSize && siblingSize > 300 ? `${siblingSize - 190}px` : "600px",
                }}
              >
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      {filteredStudentList.map((student) => (
                        <TableRow
                          key={student.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "&:first-of-type td": { borderTop: "1px solid rgb(233, 236, 239)" },
                          }}
                        >
                          <TableCell width="60px">
                            <Checkbox
                              checked={selectedStudentIds.includes(student.id)}
                              disabled={
                                assignment &&
                                assignment.assignmentId &&
                                assignment.assignmentId > 0 &&
                                assignment.users &&
                                assignment.users.length > 0 &&
                                assignment.users.includes(student.id)
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedStudentIds([...selectedStudentIds, student.id]);
                                } else {
                                  const filteredStudentIds = selectedStudentIds.filter(
                                    (s) => s !== student.id
                                  );
                                  setSelectedStudentIds(filteredStudentIds);

                                  if (selectAllStudents) {
                                    setSelectAllStudents(false);
                                  }
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell component="td" scrope="row">
                            {`${student.name} ${student.surname} (${student.studentNo}) ${student.className}`}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Card>
          </div>
        </Grid>
        <Grid item lg={7} xs={12}>
          <SuiButton
            variant="contained"
            color="info"
            fullWidth
            onClick={(e) => {
              e.preventDefault();
              const errorMessage = [];

              if (assignment && assignment.assignmentId && assignment.assignmentId > 0) {
                if (
                  audioUrl &&
                  audioUrl !== "" &&
                  audioData &&
                  (!audioMessageDoUrl || audioMessageDoUrl === "")
                ) {
                  errorMessage.push(
                    localizations?.RECORD_NOT_APPROVED_ERROR ??
                      "Ses kaydınızı lütfen onaylayınız. Ses kaydını iptal etmek için lütfen iptal butonuna tıklayınız."
                  );
                }

                if (errorMessage.length === 0) {
                  const updatedAssignment = {
                    assignmentId: assignment.assignmentId,
                    bookId: assignment.bookId,
                    users: assignment.users,
                    newUsers: _.difference(selectedStudentIds, assignment.users),
                    message: assignment.message,
                    newMessage: message !== assignment.message ? message : null,
                    audioMessageUrl: assignment.audioMessageUrl,
                    newAudioMessageUrl:
                      audioMessageDoUrl !== assignment.audioMessageUrl ? audioMessageDoUrl : null,
                    newDueDate: termin !== assignment.dueDate ? termin : null,
                    tasks: assignment.tasks.map((t) => ({ taskType: t.taskType, taskId: t.id })),
                  };

                  removeAudioUploadingParameters();
                  updateExistingAssignment({ assignment: updatedAssignment });
                } else {
                  Swal.fire({
                    icon: "error",
                    title: localizations?.MISSING_INFO_ERROR_TITLE ?? "Eksik Bilgi",
                    html: `
                    <p class="error-desc">
                      ${
                        localizations?.MISSING_INFO_ERROR_DESC ??
                        "Zorunlu alan girişleri eksik yapıldı. Lütfen aşağıda belirtilen eksiklikleri gidererek tekrar deneyiniz."
                      }
                    </p>
                    <ul class="message-error-list">
                      ${errorMessage
                        .map(
                          (errMessage) => `
                        <li class="message-error-item">* ${errMessage}</li>
                      `
                        )
                        .join("")}
                    </ul>
                  `,
                    showCancelButton: false,
                    confirmButtonText: localizations?.MESSAGE_CONFIRM_BTN ?? "Tamam",
                  }).then(() => {
                    // Cancelled
                  });
                }
              } else {
                if (!selectedBookId || selectedBookId < 1) {
                  errorMessage.push(
                    localizations?.BOOK_NOT_SELECTED_ERROR ?? "Kitap seçimi yapmak zorundasınız."
                  );
                }

                const { reading, listening, recording, quiz, material } = selectedTaskTypes;

                if (!reading && !listening && !recording && !quiz && !material) {
                  errorMessage.push(
                    localizations?.TASK_TYPE_NOT_SELECTED_ERROR ?? "En az 1 görev tipi seçiniz."
                  );
                }

                if (quiz && selectedQuizIds.length === 0) {
                  errorMessage.push(
                    localizations?.QUIZ_NOT_SELECTED_ERROR ??
                      "Quiz görev tipi seçildiğinden en az 1 adet quiz seçilmelidir."
                  );
                }

                if (material && selectedMaterialIds.length === 0) {
                  errorMessage.push(
                    localizations?.MATERIAL_NOT_SELECTED_ERROR ??
                      "Materyal görev tipi seçildiğinden en az 1 adet materyal seçilmelidir."
                  );
                }

                if (selectedStudentIds.length === 0) {
                  errorMessage.push(
                    localizations?.STUDENT_NOT_SELECTED_ERROR ??
                      "Görev en az 1 öğrenciye atanmalıdır. Lütfen en az 1 öğrenci seçiniz."
                  );
                }

                if (!termin || termin === "") {
                  errorMessage.push(
                    localizations?.DUE_DATE_NOT_PICKED_ERROR ?? "Görev bitiş tarihi seçiniz."
                  );
                }

                if (
                  audioUrl &&
                  audioUrl !== "" &&
                  audioData &&
                  (!audioMessageDoUrl || audioMessageDoUrl === "")
                ) {
                  errorMessage.push(
                    localizations?.RECORD_NOT_APPROVED_ERROR ??
                      "Ses kaydınızı lütfen onaylayınız. Ses kaydını iptal etmek için lütfen iptal butonuna tıklayınız."
                  );
                }

                if (errorMessage.length === 0) {
                  const tasks = [];
                  if (reading) {
                    tasks.push({ taskType: "READ" });
                  }

                  if (listening) {
                    tasks.push({ taskType: "LISTEN" });
                  }

                  if (recording) {
                    tasks.push({ taskType: "RECORD" });
                  }

                  if (quiz) {
                    selectedQuizIds.forEach((qId) =>
                      tasks.push({ taskType: "QUIZ", elementId: qId })
                    );
                  }

                  if (material) {
                    selectedMaterialIds.forEach((mId) =>
                      tasks.push({ taskType: "MATERIAL", elementId: mId })
                    );
                  }

                  const newAssignment = {
                    bookId: selectedBookId,
                    listen: listening,
                    read: reading,
                    record: recording,
                    quiz,
                    material,
                    users: selectedStudentIds,
                    message,
                    audioMessageUrl: audioMessageDoUrl,
                    tasks,
                    dueDate: termin,
                  };

                  removeAudioUploadingParameters();
                  saveNewAssignment({ assignment: newAssignment });
                } else {
                  Swal.fire({
                    icon: "error",
                    title: localizations?.MISSING_INFO_ERROR_TITLE ?? "Eksik Bilgi",
                    html: `
                    <p class="error-desc">
                      ${
                        localizations?.MISSING_INFO_ERROR_DESC ??
                        "Zorunlu alan girişleri eksik yapıldı. Lütfen aşağıda belirtilen eksiklikleri gidererek tekrar deneyiniz."
                      }
                    </p>
                    <ul class="message-error-list">
                      ${errorMessage
                        .map(
                          (errMessage) => `
                        <li class="message-error-item">* ${errMessage}</li>
                      `
                        )
                        .join("")}
                    </ul>
                  `,
                    showCancelButton: false,
                    confirmButtonText: localizations?.MESSAGE_CONFIRM_BTN ?? "Tamam",
                  }).then(() => {
                    // Cancelled
                  });
                }
              }
            }}
          >
            {localizations?.SEND_BTN ?? "Gönder"}
          </SuiButton>
        </Grid>
      </Grid>
      <QuizPreviewDialog
        open={modalOpen && previewQuiz !== null}
        onClose={() => setModalOpen(false)}
        quiz={previewQuiz}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  allBooks: state.data.minimizedBooks ?? [],
  // allBooks: state.data.books ? Object.values(state.data.books).filter((b) => b.isPublished) : [],
  lang: state.localization?.language ?? "TR",
  localizations: state.localization?.pairs,
  myStudents: state.data.students ?? [],
  uploadParameters: state.data.uploadParameters,
  redirectTo: state.ui.redirectTo,
  apiErrorMsg: state.message[SAVE_ASSIGNMENT],
  bookQuizList: state.data.bookQuizList,
  bookMaterialList: state.data.bookMaterialList,
  previewQuiz: state.data.previewQuiz,
});

const mapDispatchToProps = {
  getMyStudents: getAssignmentStudents,
  getAudioUploadingParameters: getAudioUploadParams,
  removeAudioUploadingParameters: removeAudioUploadParams,
  saveNewAssignment: saveAssignment,
  updateExistingAssignment: updateAssignment,
  startPage: startAddOrEditAssignment,
  removeApiErrorMsg: removeMessage,
  saveUnsavedData: saveUnsavedAssignment,
  getQuizList: getQuizListOfBook,
  getMaterialList: getMaterialListOfBook,
  getPreviewQuiz: getQuizDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditAssignment);

// flatpickr(".flatpickr", {
//   locale: language.zh,
// });
