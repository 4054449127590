import React, { useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import { BookOpen, Headphones, Microphone, Question, Files } from "phosphor-react";

import SuiBox from "components/SuiBox";
import DashboardLayout from "custom/LayoutContainers/DashboardLayout";
import Loading from "custom/Loading";
import DashboardNavbar from "custom/Navbars/DashboardNavbar";
import EventCalendar from "custom/Calendar";
import Footer from "custom/Footer";
import requireAuth from "hocs/requireAuth";
import { startCalendar } from "redux/actions/pages";
import "./_calendar.css";

const Calendar = ({ startPage, assignments }) => {
  const history = useHistory();

  useEffect(() => {
    startPage();
  }, []);

  return (
    <>
      <Loading />
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={12} sx={{ height: "max-content" }}>
              {useMemo(
                () => (
                  <EventCalendar
                    initialView="dayGridMonth"
                    events={assignments?.map((as) => ({
                      assignment: as,
                      date: as.dueDate.split(".").reverse().join("-"),
                    }))}
                    eventClick={(info) => {
                      const assignment = info?.event?.extendedProps?.assignment;
                      history.push({
                        pathname: "/assignment",
                        state: { assignmentId: assignment.id },
                      });
                    }}
                    eventContent={(eventInfo) => {
                      const assignment = eventInfo?.event?.extendedProps?.assignment;

                      return (
                        <div className="event-container">
                          <div className="event-title">{assignment?.book?.title}</div>
                          <div className="event-tasks">
                            {assignment.read ? (
                              <div className="event-task-item">
                                <BookOpen weight="duotone" size={16} color="white" />
                              </div>
                            ) : null}
                            {assignment.listen ? (
                              <div className="event-task-item">
                                <Headphones weight="duotone" size={16} color="white" />
                              </div>
                            ) : null}
                            {assignment.record ? (
                              <div className="event-task-item">
                                <Microphone weight="duotone" size={16} color="white" />
                              </div>
                            ) : null}
                            {assignment.quiz ? (
                              <div className="event-task-item">
                                <Question weight="duotone" size={16} color="white" />
                              </div>
                            ) : null}
                            {assignment.material ? (
                              <div className="event-task-item">
                                <Files weight="duotone" size={16} color="white" />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      );
                    }}
                  />
                ),
                []
              )}
            </Grid>
          </Grid>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

Calendar.defaultProps = {
  assignments: [],
};

Calendar.propTypes = {
  startPage: PropTypes.func.isRequired,
  assignments: PropTypes.arrayOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  assignments:
    state.data.assignments !== undefined && state.data.assignments !== null
      ? Object.values(state.data.assignments)
      : [],
});

const mapDispatchToProps = {
  startPage: startCalendar,
};

export default connect(mapStateToProps, mapDispatchToProps)(requireAuth(Calendar));
