export const TOKEN_STORAGE_KEY = "EdictDigitalToken";
export const LANGUAGE_STORAGE_KEY = "EdictDigitalLanguage";

export const BASE_URL = "https://yedek.okumaplatformu.com"; // Backend
export const ROOT_URL = "yedek-accounts.okumaplatformu.com";
export const PRODUCT_TYPE = "FRONTEND-ACCOUNTS";
export const MAIN_FRONTEND_URL = "https://yedek.okumaplatformu.com";
export const EDICT_DASHBOARD_URL = "https://edict.okumaplatformu.com";

export const CLIENT = "ACCOUNTS";

// LOOKUP KEYS
export const AUTHORS = "AUTHORS";
export const ILLUSTRATORS = "ILLUSTRATORS";
export const TRANSLATORS = "TRANSLATORS";
export const LANGUAGES = "LANGUAGES";
export const GRADES = "GRADES";
export const SERIES = "SERIES";
export const LEVELS = "LEVELS";
export const CATEGORIES = "CATEGORIES";
export const GENRES = "GENRES";
export const KEYWORDS = "KEYWORDS";
export const SUBJECT_AREAS = "SUBJECTS";
export const USES = "USES";
export const PUBLISHERS = "PUBLISHERS";
export const TEXT_STYLES = "TEXT-STYLES";
export const IMAGE_STYLES = "IMAGE-STYLES";
export const COURSES = "COURSES";
export const SKILLS = "SKILLS";
export const UNITS = "UNITS";
export const OBJECTIVES = "OBJECTIVES";
export const SPECIAL_DAYS = "SPECIAL_DAYS";

// MOST USED ROUTES
export const SIGNIN_ROUTE = "/signin";
